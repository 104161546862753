import _ from 'lodash';
import Cookies from 'js-cookie';
import { sessionDomain } from '@/constants/config';

export function saveSuccessTokenId(SCT_ID, exp = 30) {
  return Cookies.set('SCT_ID', SCT_ID, { expires: exp, domain: sessionDomain });
}

export function saveEnSctId(enSctId) {
  return Cookies.set('enSctId', enSctId, { expires: 30, domain: sessionDomain });
}

export function getEnSctId() {
  return Cookies.get('enSctId', { domain: sessionDomain });
}

export function removeEnSctId() {
  return Cookies.remove('enSctId', { domain: sessionDomain });
}

export function removeSTD() {
  return Cookies.remove('STD', { domain: sessionDomain });
}

export function saveSuccessTokenData(SCT_ID, uniNum, enSctId) {
  let getExistsStd = Cookies.get('STD', { domain: sessionDomain });
  try {
    if (JSON.parse(getExistsStd)) {
      getExistsStd = JSON.parse(getExistsStd);
    } else {
      getExistsStd = {};
    }
  } catch (e) {
    getExistsStd = {};
  }

  const stdObj = !_.isEmpty(getExistsStd)
    ? { ...getExistsStd, [uniNum]: { SCT_ID, enSctId } }
    : { [uniNum]: { SCT_ID, enSctId } };

  return Cookies.set('STD', JSON.stringify(stdObj), { expires: 30, domain: sessionDomain });
}

export function getSuccessTokenData() {
  return Cookies.get('STD', { domain: sessionDomain });
}

export function getSuccessTokenId() {
  return Cookies.get('SCT_ID', { domain: sessionDomain });
}

export function removeSuccessTokenId() {
  return Cookies.remove('SCT_ID', { domain: sessionDomain });
}

export function removeSuccessTokenDataWithId(uniNum) {
  let getExistsStd = Cookies.get('STD', { domain: sessionDomain });
  try {
    if (JSON.parse(getExistsStd)) {
      getExistsStd = JSON.parse(getExistsStd);
      getExistsStd[uniNum] = undefined;
    } else {
      getExistsStd = {};
    }
  } catch (e) {
    getExistsStd = {};
  }
  return Cookies.set('STD', JSON.stringify(getExistsStd), { expires: 30, domain: sessionDomain });
}

export function removeSuccessTokenDataWithSctId(SCT_ID) {
  let getExistsStd = Cookies.get('STD', { domain: sessionDomain });
  try {
    if (JSON.parse(getExistsStd)) {
      getExistsStd = JSON.parse(getExistsStd);
    } else {
      getExistsStd = {};
    }
    if (_.isEmpty(getExistsStd)) {
      return Cookies.remove('STD', { domain: sessionDomain });
    } else {
      for (const [key, { SCT_ID: value }] of Object.entries(getExistsStd)) {
        if (value === SCT_ID) {
          getExistsStd[key] = undefined;
        }
      }
      return Cookies.set('STD', JSON.stringify(getExistsStd), { expires: 30, domain: sessionDomain });
    }
  } catch (e) {
    return Cookies.remove('STD', { domain: sessionDomain });
  }
}

export const removeAuthTokens = SCT_ID => {
  removeSuccessTokenId();
  removeSuccessTokenDataWithSctId(SCT_ID);
  removeEnSctId();
  removeSTD();
  removeWorkspaceId();
};

export function isSctIdEmpty() {
  const sctId = getSuccessTokenId();
  return _.isEmpty(sctId);
}

export const getWorkspaceId = () => {
  return Cookies.get('wsId', { domain: sessionDomain });
};

export const saveWorkspaceId = (wsId, exp = 30) => {
  return Cookies.set('wsId', wsId, { expires: exp, domain: sessionDomain });
};

export function removeWorkspaceId() {
  return Cookies.remove('wsId', { domain: sessionDomain });
}
