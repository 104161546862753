import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { baseApi } from './services/api';
import { baseCasApi } from './services/cas/casApi';
import { baseWsApi } from './services/ws/wsApi';
import cartPageReducer from '@/container/CartPage/slice';
import homePageReducer from './container/HomePage/slice';
import shopPageReducer from './container/ShopPage/slice';
import collectionPageReducer from '@/container/CollectionPage/slice';
import couponPageReducer from '@/container/CouponPage/slice';
import depositPageReducer from '@/container/DepositPage/slice';
import orderPageReducer from '@/container/OrderPage/slice';
import notifyPageReducer from '@/container/NotifyPage/slice';
import billPageReducer from '@/container/BillPage/slice';
export const history = createBrowserHistory();

export default configureStore({
  reducer: {
    [baseCasApi.reducerPath]: baseCasApi.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
    [baseWsApi.reducerPath]: baseWsApi.reducer,
    homePageReducer,
    shopPageReducer,
    cartPageReducer,
    collectionPageReducer,
    couponPageReducer,
    depositPageReducer,
    orderPageReducer,
    notifyPageReducer,
    billPageReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(baseApi.middleware, baseCasApi.middleware, baseWsApi.middleware),
  devTools: process.env.REACT_APP_ENV === 'dev',
});
