import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getSuccessTokenId } from '@/utils/token';

const baseUrl = process.env.REACT_APP_CAS_API_URL;

export const baseCasApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      headers.set('sctId', `${getSuccessTokenId()}`);
      return headers;
    },
  }),
  reducerPath: 'baseCasApiReducer',
  endpoints: () => ({}),
});
