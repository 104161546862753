import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getSuccessTokenId } from '@/utils/token';
import { apiUrl } from '@/constants/config';

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: headers => {
      headers.set('sctId', `${getSuccessTokenId()}`);
      return headers;
    },
  }),
  reducerPath: 'baseApi',
  endpoints: () => ({}),
});
